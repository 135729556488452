import { getQueryValue } from '../utils/browser'

export const initAudioEyeFn = audioEyeEnabled => {
  ;(window as any).GCI.audioEye_whenDOMLoads = function () {
    // If the audioEyeEnabled site config in the bcc is true then load audioeyes script

    if (audioEyeEnabled && getQueryValue('audioEyeDisabled') !== 'true') {
      //* *REQUIRED: Custom Button Text based on AudioEye CTA state**
      const offButton = 'Turn AudioEye Tools On'
      const onButton = 'Turn AudioEye Tools Off'

      // We add an event listener to our script, so that we are aware each time
      // a user takes an action to change the state of the AudioEye CTA
      document.addEventListener('ae-active-change', () => {
        // Get DOM Elements we need to manipulate on state change
        const button = document.getElementById('audioeye_activate_btn')

        if (button !== null) {
          // IF the state of AudioEye is active...
          if ((window as any).AudioEye.isActive()) {
            // ...do these things
            button.innerHTML = onButton

            // ELSE the state of AudioEye is inactive...
          } else {
            // ...so do these things
            button.innerHTML = offButton
          }

          // Set display states of the DOM Elements to  be visable
          button.setAttribute('style', 'display: inline-block')
        }
      })
      const audioEyeScript = document.createElement('script')
      audioEyeScript.async = true
      audioEyeScript.type = 'text/javascript'
      audioEyeScript.text =
        '!function(){var t=function(){var t=document.createElement("script");t.src="//ws.audioeye.com/ae.js",t.type="text/javascript",t.setAttribute("async",""),document.getElementsByTagName("body")[0].appendChild(t)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",t):window.attachEvent&&window.attachEvent("onload",t):t()}()'
      document.body.appendChild(audioEyeScript)
    }
  }
}
