import { isEmpty, isObject } from 'lodash'
import { useEffect } from 'react'

import { useHeaderUserInfo } from '../../hooks/header'
import type { IIdentityLoggedUser } from './../../api/header/index'

export const useUserLoginStatus = (): {
  isUserLogin: boolean
  userEmail: string
  isLoading: boolean
  userFirstName: string
  userLastName: string
} => {
  const {
    identityLoggedUser: {
      sourceCodeId,
      sourceCodeName,
      email = '',
      firstName = '',
      lastName = '',
      value
    } = {} as IIdentityLoggedUser,
    isLoading = false
  } = useHeaderUserInfo()

  // according to ECMD-16404
  // set sourceCodeId and sourceCodeName to window.GCI.digitalData
  useEffect(() => {
    if (
      !isEmpty(sourceCodeId) &&
      !isEmpty(sourceCodeName) &&
      isObject((window as any)?.GCI?.digitalData)
    ) {
      ;(window as any).GCI.digitalData.sourceCodeId = sourceCodeId
      ;(window as any).GCI.digitalData.sourceCodeName = sourceCodeName
    }
  }, [sourceCodeId, sourceCodeName])

  return {
    isUserLogin: Boolean(value),
    userEmail: email,
    userFirstName: firstName,
    userLastName: lastName,
    isLoading
  }
}
