import type { LocationTypings } from '../../typings/location'
import ATGService from '../request'

function stateSort(a, b) {
  const keyA = a?.key?.toUpperCase()
  const keyB = b?.key?.toUpperCase()
  if (keyA < keyB) {
    return -1
  }
  if (keyA > keyB) {
    return 1
  }
  return 0
}

export async function updateUserLocale(
  countryCode: string,
  currencyCode: string
): Promise<unknown> {
  const res = await ATGService({
    url: '/atg/userprofiling/ProfileActor/updateUserLocale',
    method: 'post',
    data: {
      countryCode,
      currencyCode
    },
    needSession: true
  })

  return res.data
}
export const getOtherState = async (
  country: string
): Promise<LocationTypings.IState[]> => {
  const result = await ATGService({
    url: '/atg/userprofiling/ProfileActor/getStatesByCountryCode',
    method: 'post',
    data: {
      countryCode: country
    }
  })
  const countryStates =
    result?.data?.states?.map(item => ({
      name: item.displayName,
      key: item.code
    })) || []
  return countryStates.sort(stateSort)
}

export const changeDeliveryZipCode = async params => {
  const res = await ATGService({
    method: 'post',
    url: `/ngp/rest/actor/DropletActor/getShipMessage?source=shipMessage`,
    data: params?.mutationData,
    params: params.zipCodeParams,
    needSession: false,
    needSPAFlag: false
  })

  return res?.data?.result
}
export const getGlobalStock = async params => {
  const res = await ATGService({
    url: '/ngp/rest/actor/DropletActor/stockInfo',
    method: 'GET',
    params: { ...params, global: true }
  })
  const stockData = res?.data?.result
  return stockData?.global
}
