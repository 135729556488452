import { initLiveChatEvent } from '../utils/liveChat'
import { loadScript } from '../utils/script'
const loadAdobeDTM = () => {
  loadScript({
    src: process.env.NEXT_PUBLIC_ADOBE_DTM_URL!,
    strategy: 'lazyOnload',
    onLoad: () => {
      initLiveChatEvent()
    }
  })
}
export const initHelpButton = () => {
  // don't need to reload jquery
  if (!(window as any).$) {
    loadScript({
      src: process.env.NEXT_PUBLIC_JQUERY_URL!,
      strategy: 'lazyOnload',
      onLoad: () => {
        loadAdobeDTM()
      }
    })
  } else {
    loadAdobeDTM()
  }
}
