import { includes, isString, replace, some } from 'lodash'

export const getNumberFromString = (value: string): string => {
  if (!isString(value)) {
    return ''
  }
  return value.replace(/[^0-9]/gi, '')
}

// return xxxx xxxx xxxx xxxx type string
export const addCardNumberSpace = (value: string): string => {
  if (!isString(value)) {
    throw new Error('addCardNumberSpace: value must be a valuable string')
  }
  const cardNumber = getNumberFromString(value)
  return cardNumber
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}

export function getCreditCardTypeByNumber(cardNumber: string): string {
  if (!isString(cardNumber) || !cardNumber) {
    console.error(
      'getCreditCardTypeByNumber: cardNumber must be a valuable string'
    )
    return ''
  }

  const masterRegExp =
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/
  const visaRegExp = /^4[0-9]{12}(?:[0-9]{3})?$/
  const amexRegExp = /^3[47][0-9]{13}$/
  const discoverRegExp =
    /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/

  const creditCardInfo = [
    {
      name: 'Master Card',
      regExp: masterRegExp
    },
    {
      name: 'Visa',
      regExp: visaRegExp
    },
    {
      name: 'American Express',
      regExp: amexRegExp
    },
    {
      name: 'Discover',
      regExp: discoverRegExp
    }
  ]

  for (const creditCard of creditCardInfo) {
    const { regExp, name } = creditCard
    if (regExp.test(cardNumber)) {
      return name
    }
  }
  return 'unknown'
}

export function paddingLeftWithStar(value: string, length: number): string {
  if (!isString(value) || !value) {
    throw new Error(
      'paddingLeftWithStar: origin value must be a valuable string'
    )
  }
  return value.padStart(length, '*')
}

export function paddingLeftWithStarTo16WithRight4(value: string): string {
  if (!isString(value) || !value) {
    throw new Error(
      'paddingLeftWithStarTo16WithRight4: origin value must be a valuable string'
    )
  }
  return paddingLeftWithStar(value.slice(-4), 16)
}

export function isStringStartWithStar(value: string): boolean {
  if (!isString(value) || !value) {
    console.error(
      'isStringStartWithStar: origin value must be a valuable string'
    )
    return false
  }
  return value.startsWith('*')
}

export const covertValidCardNumberFromString = (cardNumber: string): string => {
  if (!isString(cardNumber) || !cardNumber) {
    throw new Error(
      'covertValidCardNumberFromString: cardNumber must be a valuable string'
    )
  }
  if (cardNumber?.length === 4 || isStringStartWithStar(cardNumber)) {
    return paddingLeftWithStarTo16WithRight4(cardNumber)
  }
  return getNumberFromString(cardNumber)
}

// convert 1234123412341234 ---> 123412341234****
export const convertCardNumberToEncrypted = (cardNumber: string): string => {
  if (!isString(cardNumber) || !cardNumber) {
    throw new Error(
      'convertCardNumberToEncrypted: cardNumber must be a valuable string'
    )
  }

  const lastFourNumber = cardNumber.slice(-4)
  return lastFourNumber.padStart(cardNumber.length, '*')
}

const IMG_SIZE_REGEXP = /-\d+x\d+(\.[a-zA-Z]+)$/g
const AKAMAI_IMG_DOMAIN = ['www.guitarcenter.com', 'static.guitarcenter.com']
export const specifyImageSize = (imgSrc: string, imgSize: number): string => {
  if (!isString(imgSrc) || !imgSrc) {
    throw new Error('specifyImageSize: imgSrc must be a valuable string')
  }

  const supportAkamaiOptimization = some(AKAMAI_IMG_DOMAIN, domain =>
    includes(imgSrc, domain)
  )
  if (supportAkamaiOptimization) {
    return includes(imgSrc, '?')
      ? `${imgSrc}&imwidth=${imgSize}`
      : `${imgSrc}?imwidth=${imgSize}`
  }

  if (IMG_SIZE_REGEXP.test(imgSrc)) {
    return imgSrc.replace(IMG_SIZE_REGEXP, `-${imgSize}x${imgSize}$1`)
  }

  return imgSrc
}

export const convertNumberToWords = (num: number): string => {
  const ones = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen'
  ]
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'
  ]

  const numString = num.toString()

  if (num < 0) throw new Error('Negative numbers are not supported.')

  if (num === 0) return 'zero'

  // the case of 1 - 20
  if (num < 20) {
    return ones[num]
  }

  if (numString.length === 2) {
    return `${tens[numString[0]]} ${ones[numString[1]]}`
  }

  // 100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0')
      return `${ones[numString[0]]} hundred`
    return `${ones[numString[0]]} hundred and ${convertNumberToWords(
      Number(numString[1] + numString[2])
    )}`
  }

  if (numString.length === 4) {
    const end = Number(numString[1] + numString[2] + numString[3])
    if (end === 0) return `${ones[numString[0]]} thousand`
    if (end < 100)
      return `${ones[numString[0]]} thousand and ${convertNumberToWords(end)}`
    return `${ones[numString[0]]} thousand ${convertNumberToWords(end)}`
  }
  return ''
}

export const getFieldByStringName = (object: any, fields: string): any => {
  const arr = fields.split('.')
  const key = arr.shift()
  const value = key ? object[key] : undefined
  if (!value) return value
  if (arr?.length) {
    return getFieldByStringName(value, arr.join('.'))
  }
  return value
}

export const formatStringToPhoneNumber = (phoneNumber: string): string => {
  if (!isString(phoneNumber) || !phoneNumber) {
    return ''
  }
  const strTemp = phoneNumber.replace(/\D/g, '')
  const a = strTemp.slice(0, 3)
  const b = strTemp.slice(3, 6)
  const c = strTemp.slice(6, 10)
  if (a && b && c) {
    return `(${a}) ${b}-${c}`
  }
  if (a && b) {
    return `(${a}) ${b}`
  }
  if (a) {
    return `(${a})`
  }
  return ''
}

// 1234567890 ---> 1,234,567,890
export function convertNumberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function extractDigitsAndDecimalSymbol(str: string) {
  return replace(str, /[^0-9.]/g, '')
}

export const removeSITE5Prefix = (str: string) => {
  return str.replace(/^site5/, '')
}
