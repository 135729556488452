import EventEmitter from 'events'

export const eventEmitter = new EventEmitter()

export const CHANGE_COUNTRY_EVENT = 'change-country'

export const SIGN_IN_EVENT = 'sign-in-event'

export const CHANGE_STORE_EVENT = 'change-store-list'

export const ADDRESS_VALIDATION_EVENT = 'address-validation-event'

export const CHECKOUT_SHIPPING_ADDRESS_MANUALLY =
  'checkout-shipping-address-manually'

export const UPDATE_PICK_UP_ADDRESS_FORM = 'update-pick-up-address-form'

export const UPDATE_SHIPPING_ADDRESS_FORM = 'update-shipping-address-form'

export const CHANGE_PREV_STORAGE_EVENT = 'change-prev-storage-event'

export const CHANGE_ALGOLIA_STORE_EVENT = 'change-algolia-store-list'

export const CHANGE_ALGOLIA_PREV_STORAGE_EVENT =
  'change-algolia-prev-storage-event'

export const ALGOLIA_FILTER_EVENT = 'algolia-filter-event'

export const ALGOLIA_CHOOSE_CITY_STATE_EVENT = 'algolia-choose-city-state-event'
