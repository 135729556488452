import type { StoreTypings } from '../../typings/store-message'
import { isArrayAndNotEmpty } from '../../utils/lodash'
import { logger } from '../../utils/logger'
import ATGService, { thirdPartyService } from '../request'
export const getLessonStore = async ({ location }) => {
  try {
    const { data = {} } = await thirdPartyService({
      url: 'https://rqw9jvf3hj.execute-api.us-west-2.amazonaws.com/prod/v1/search',
      method: 'GET',
      params: {
        location,
        radius: 50,
        lessonsOnly: true
      }
    })
    let stores = [] as any
    const { results } = data
    if (isArrayAndNotEmpty(results)) {
      stores = results.map(result => ({
        storeId: result.storeId,
        address: result.address
      }))
    }
    return stores
  } catch (error) {
    logger.error({
      message: 'getLessonStore error --->',
      error
    })
    return []
  }
}

export const addHomeStoreToProfile = async storeId => {
  const res = await ATGService({
    url: '/atg/account/myaccountActor/addHomeStoreToProfile',
    method: 'GET',
    params: { locationId: storeId },
    needSession: true
  })
  return res?.data
}

export const getClosestStoreList = async params => {
  const myStore = await ATGService({
    url: '/ngp/rest/actor/DropletActor/GCSortNearestStoreDroplet-SPA',
    method: 'GET',
    params: { ...params }
  })
  return myStore?.data
}

export const getSearchStoreList = async (
  params
): Promise<StoreTypings.IStoreLocationResults> => {
  const res = await ATGService({
    url: '/ngp/rest/actor/DropletActor/StoreLocationDroplet',
    method: 'GET',
    params: { ...params }
  })

  return res?.data
}

export const getStoreConfig = async (
  params: string[] | undefined
): Promise<Record<string, unknown> | undefined> => {
  if (!params) return undefined
  const res = await ATGService({
    url: '/ngp/rest/actor/DropletActor/fetchPropFromStoreConfig',
    method: 'GET',
    params: { prop: params.join() }
  })
  return res?.data?.value
}

export const storeInfoLookup = async payload => {
  const { latitude, longitude, ...store } = payload
  const { data } = await ATGService({
    url: `/ngp/rest/actor/DropletActor/StoreInfoLookup?latitude=${latitude}&longitude=${longitude}`,
    method: 'post',
    data: { ...store, distanceByUserLocation: true },
    needSession: true
  })
  return data
}
