import BigNumber from 'bignumber.js'
import currency from 'currency.js'
import { isNaN } from 'lodash'

//  convert 10000 => 10,000 or 10000 => $10,000
export const formatCurrency = (
  value: number | string | currency,
  currencySymbol = ''
): string => {
  if (value instanceof currency) {
    return value.format({
      symbol: currencySymbol
    })
  }
  const finalValue = new BigNumber(value).toNumber().toFixed(2)
  if (isNaN(finalValue)) {
    throw new Error('formatCurrency: value must be a number or a currency')
  } else {
    return currency(finalValue).format({
      symbol: currencySymbol
    })
  }
}

export const convertBigNumberToNumber = (value: number | string) => {
  const finalValue = new BigNumber(value).toNumber().toFixed(2)
  if (isNaN(finalValue)) {
    throw new Error('formatCurrency: value must be a number or a currency')
  } else {
    return Number(finalValue)
  }
}
