import { isFunction } from 'lodash'

import {
  GC_CART_PAGE_INFO_QUERY_KEY,
  QUERY_KEY_MINI_CART
} from '../../constants/query/cart'
import { QUERY_KEY_HEADER_USER_INFO } from '../../constants/query/header'

// Must set window.queryClient in _app.tsx
export function refreshMiniCart(queryClient = (window as any).queryClient) {
  if (isFunction(queryClient?.invalidateQueries)) {
    // For new React header
    queryClient.invalidateQueries([QUERY_KEY_HEADER_USER_INFO])
    queryClient.invalidateQueries([QUERY_KEY_MINI_CART])

    if (window?.location?.pathname === '/cart') {
      queryClient.invalidateQueries([GC_CART_PAGE_INFO_QUERY_KEY])
    }
  }

  // For old JSP header
  if (isFunction((window as any)?.MFI?.MiniCart?.refresh)) {
    ;(window as any).MFI.MiniCart.refresh(true)
  }
}
