import { addHomeStoreToProfile } from '@gc/common-lib/api/pdp/store'
import { QUERY_KEY_HEADER_USER_INFO } from '@gc/common-lib/constants/query/header'
import { formatCurrency } from '@gc/common-lib/utils/currency'
import { logger } from '@gc/common-lib/utils/logger'
import { defaultActionParams, save } from '@store/global'
import type { RootState } from '@store/index'
import { store } from '@store/index'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Steps } from '@typings/global'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useSelector } from 'react-redux'

// ======= useRadioCheckBox ========
interface IRadioCheckBox {
  id: string
  checked: boolean
  [propName: string]: any
}
type IChangeCheckBoxType = (
  id: string,
  callBack?: (currentId: string) => void
) => void
interface IRadioCheckBoxBack {
  changeCheckBox: IChangeCheckBoxType
  radioCheckBoxArray?: IRadioCheckBox[] | undefined
  setRadioCheckBoxArray: (params: IRadioCheckBox[] | undefined) => void
}
export const useRadioCheckBox = (
  radioCheckBox?: IRadioCheckBox[]
): IRadioCheckBoxBack => {
  const [radioCheckBoxArray, setRadioCheckBoxArray] = useState<
    IRadioCheckBox[] | undefined
  >([])
  useEffect(() => {
    setRadioCheckBoxArray(radioCheckBox)
  }, [radioCheckBox])

  /**
   * @param id Current selection id
   * @param callBack callBack function after selecting id
   */
  const changeCheckBox: IChangeCheckBoxType = (
    id: string,
    callBack?: (currentId: string) => void
  ) => {
    let currentCheck = ''
    const hasChangedArray = radioCheckBoxArray?.map(item =>
      id === item.id
        ? { ...item, checked: !item.checked }
        : { ...item, checked: false }
    )
    setRadioCheckBoxArray(hasChangedArray)
    hasChangedArray?.forEach(item => {
      if (item.checked) {
        currentCheck = item.id
      }
    })
    if (callBack) callBack(currentCheck)
  }

  return { changeCheckBox, radioCheckBoxArray, setRadioCheckBoxArray }
}

export const useSessionConfirmationNumber = () => {
  const [sessionConfirmationNumber, setSessionConfirmationNumber] = useState('')
  useEffect(() => {
    setSessionConfirmationNumber(localStorage.getItem('dynSessConf') || '')
  }, [])

  return {
    sessionConfirmationNumber,
    setSessionConfirmationNumber
  }
}

export const useFormatCurrency = (currencySymbol: string) => {
  return useCallback(
    (price: number) => formatCurrency(price, currencySymbol),
    [currencySymbol]
  )
}

export const useMutationAddHomeStore = () => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: (storeInfo: any) =>
      addHomeStoreToProfile(storeInfo?.id || storeInfo?.storeId),
    onSuccess: async (_data, storeInfo) => {
      const currentTime = new Date().getTime()
      // adapt the jsp header homeStore
      localStorage.setItem('savedTimeStamp', String(currentTime))
      localStorage.setItem('myStoreInfo', JSON.stringify(storeInfo))
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_HEADER_USER_INFO]
      })
    }
  })
  return { mutate }
}

export interface ISignInModal {
  activeStep: string
  setActiveStep: Dispatch<SetStateAction<Steps>>
  steps: string[]
  setSteps: Dispatch<SetStateAction<string[]>>
  changeStep: (stepStatus: Steps) => void
  resetStep: () => void
  actionParams: any
  setActionParams: any
}

export const useSignModal = (): ISignInModal => {
  const actionParams = useSelector(
    (state: RootState) => state.global.actionParams
  )
  const [steps, setSteps] = useState<string[]>([Steps.SignIn])
  const [activeStep, setActiveStep] = useState<Steps>(Steps.SignIn)

  const changeStep = (stepStatus: Steps) => {
    unstable_batchedUpdates(() => {
      const newSteps = [...steps, stepStatus]
      setSteps(newSteps)
      setActiveStep(stepStatus)
    })
  }
  const setActionParams = (params: any) => {
    store.dispatch(save({ actionParams: params }))
  }

  const resetStep = () => {
    actionParams?.options?.closeCallback?.()
    setActionParams(defaultActionParams)
    setTimeout(() => {
      unstable_batchedUpdates(() => {
        setSteps([Steps.SignIn])
        setActiveStep(Steps.SignIn)
      })
    }, 500)
  }

  return {
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    changeStep,
    resetStep,
    actionParams,
    setActionParams
  }
}

export const setCacheWithExpiry = (key, value, ttl = 24 * 60 * 60 * 1000) => {
  const item = {
    value,
    expiry: Date.now() + ttl
  }
  try {
    localStorage.setItem(key, JSON.stringify(item))
  } catch (e) {
    logger.warn('Cache storage failed', e)
  }
}

export const getCacheWithExpiry = key => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null

  const item = JSON.parse(itemStr)
  if (Date.now() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}
