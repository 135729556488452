import { COUNTRY_CODE, COUNTRY_NAME } from '../../constants/country'
import { CURRENCY_CODE } from '../../constants/currency'
import type { StoreTypings } from '../../typings/store-message'
import type { IFooterProps } from '../page-data'
import ATGService from '../request'
interface TypeAheadSEO {
  id: number
  name: string
  seoURl: string
}
interface TypeAheadDimension {
  id: number
  name: string
  values: TypeAheadSEO[]
}
export interface TypeAheadProduct {
  skuId: string
  brand: string
  imgId: string
  imgUrl: string
  linkUrl: string
  name: string
  price?: string
  rating?: number
  reviews?: number
  regularPrice?: string
  savings?: string
}
export interface TypeAheadData {
  searchValue: string
  dimensions: TypeAheadDimension[]
  terms: string[]
  recently: Omit<TypeAheadProduct, 'brand'>[]
  products: Omit<TypeAheadProduct, 'skuId'>[]
}
interface TypeAheadSearchApiProps {
  searchValue: string
  recentItems: string
}
export async function typeAheadSearchApi(
  props: TypeAheadSearchApiProps
): Promise<TypeAheadData> {
  const { data } = await ATGService({
    url: '/ngp/rest/actor/SearchActor/typeAhead',
    method: 'get',
    params: {
      recent: props.recentItems,
      Ntt: props.searchValue
    }
  })

  return {
    ...data,
    dimensions: data?.dimensions || [],
    terms: data?.terms || [],
    products: data?.products || [],
    recently: data?.recently || [],
    searchValue: props.searchValue
  }
}

export interface IArticle {
  title: string
  image: string
  articleKey: string
  'short-description': string
  'publishing-date': string
  'document-id': string
  'seo-url': string
  seoURL: string
  taxonomyKeys: string
  isFeatured: string
  isVideo: string
  taxonomyCatKeyList?: string[]
  taxonomyCatNameList?: string[]
  taxonomyCatKey?: string
  taxonomyCatName?: string
  taxonomySubCats?: {
    [key: string]: string
  }
}

export interface IDaily {
  promoEndTimestamp: number
  todaysPrice: number
  displayName: string
  promoEndDate: string
  link: string
  imageSrc: string
  listPrice: number
  rating?: number
}
export interface ISubNavigation {
  childItemName: string
  childItemLink?: string
  navigationHtml?: string
  article?: IArticle[]
  dailyPick?: IDaily
  childId: string
}
export interface IMenu {
  parentMediaName: string
  navigationId: string
  subNavigation?: ISubNavigation[]
  onSubMenuChange?: (data: any) => void
}

export interface INavigationItem {
  name: string
  link: string
  icid?: string
}
export interface ISubCategory {
  itemName?: string
  itemLink?: string
  itemNavigation: INavigationItem[]
}
interface IPromoAds {
  imgId?: string
  imgUrl: string
  linkUrl?: string
  name?: string
}
export interface IChildNavigation {
  subCategories: ISubCategory[]
  specialCategories?: INavigationItem[]
  promoAds: IPromoAds
  promoCategories: INavigationItem[]
  dailyPick?: IDaily
  article?: IArticle[]
}

interface IAlgoliaReview {
  totalReviews: number
  overallRating: number
}
export interface IAlgoliaRecentlyItems {
  skuId: string
  price: number
  review: IAlgoliaReview
  listPrice: number
  displayName: string
  imageId: string
  seoUrl: string
}
export interface INewSubNavigation {
  childItemName: string
  childItemLink?: string
  article?: IArticle[]
  dailyPick?: IDaily
  childId: string
  childNavigation?: IChildNavigation
  recentlyItems?: IAlgoliaRecentlyItems[]
}
export interface INewMenu {
  parentMediaName?: string
  navigationId: string
  subNavigation?: INewSubNavigation[]
  onSubMenuChange?: (data: any) => void
}

export interface IServerConfigData {
  recaptchaEnable: boolean
  menu: IMenu[]
  promotionBanner?: string
  footer: IFooterProps
  messageContent?: {
    contentItem: {
      GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS?: string
      GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS?: string
      GC_DEFAULT_PRE_HEADER_ROLL?: string
      GC_HEADER_LIVE_HELP_TOP_MESSAGE?: string
      GC_HEADER_LIVE_HELP_PHONE_NUMBER?: string
      GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER?: string
      GC_HEADER_LIVE_HELP_BOTTOM_LINK?: string
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT?: string
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE?: string
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL?: string
      GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME?: string
      SERIAL_ITEM_POUNDS_TEXT_KEY?: string
    }
  }
  storeConfig?: {
    value: {
      claripConsentJSUrl?: string
      enableClarip?: boolean
      audioEyeEnabled?: boolean
      loginMaxAttempts?: number
      enableLoginRecaptcha?: boolean
      storePickUpMode?: string
      internationalContextEnabled?: boolean
    }
  }
}

interface IGetSourceNodeByZipCode {
  zipCode: string
}
interface IGetSourceNodeByZipCodeResponse {
  zipCode: string
  sourceNode: string
  status: string
  message?: string
}

export const getSourceNodeByZipCode = async (
  payload: IGetSourceNodeByZipCode
): Promise<any> => {
  const { data } = await ATGService({
    url: '/ngp/rest/actor/DropletActor/getSourceNodeByZipCode',
    method: 'get',
    params: payload,
    needSPAFlag: false,
    needSession: false
  })
  const {
    zipCode = '',
    status = '',
    message = ''
  } = (data as IGetSourceNodeByZipCodeResponse) || {}
  if (status === 'success' && zipCode) {
    return {
      zipCode
    }
  }
  return {
    message
  }
}

export interface IMiniCartUserLocale {
  codeMessage?: string
  currencySymbol?: string
  countryCode: string
  countryName?: string
  currencyCode: string
  homeStore?: StoreTypings.IHomeStore
}

export interface IIdentityLoggedUser {
  sourceCodeName: string
  sourceCodeId: string
  id: string
  value: boolean
  email?: string
  firstName?: string
  lastName?: string
  ATGID?: string
  loginStatus?: string
  hashedEmail?: string
}
export interface ICommerceItemInfo {
  commerceItems?: {
    skuId: string
    imgSrc?: string
    quantity: number
    displayName: string
    commerceType?: string
    salePrice?: number
    link?: string
  }[]
}

export interface IMiniCartInfo {
  commerceItemDetails?: {
    lineCount: number
    commerceItems?: ICommerceItemInfo
    commerceTotals?: number
  }
}

export interface IResponseMiniCart {
  userLocale?: IMiniCartUserLocale
  lineCount?: number
  codeMessage?: string
  homeStore?: StoreTypings.IHomeStore
  identityLoggedUser?: IIdentityLoggedUser
  callCenterNumber?: string
}

export interface IMiniCart extends IResponseMiniCart {
  updateUserLocale: (countryCode: string, currencyCode: string) => Promise<void>
  isLoading: boolean
  updateLineCount: (order: any) => void
}

export interface IArticle {
  title: string
  'short-description': string
  'publishing-date': string
  'document-id': string
  'seo-url': string
  image: string
  taxonomyKeys: string
  'author-name': string
  isFeatured: string
  isVideo: string
  articleKey: string
  seoURL: string
  taxonomyCatKeyList?: string[]
  taxonomyCatNameList?: string[]
  taxonomyCatKey?: string
  taxonomyCatName?: string
  taxonomySubCats?: Record<string, string>
}

export const getHeaderUserInfo = async (): Promise<IResponseMiniCart> => {
  const { data } = await ATGService({
    url: '/ngp/rest/actor/DropletActor/headerUserInfo',
    method: 'GET',
    needSession: true,
    headers: { 'Cache-Control': 'no-cache' }
  })

  const {
    countryCode = COUNTRY_CODE.us,
    currencyCode = CURRENCY_CODE.us,
    currencySymbol = '$',
    countryName = COUNTRY_NAME.us,
    lineCount = 0,
    user,
    codeMessage,
    homeStore = {},
    callCenterNumber = '866-498-7882'
  } = data ?? {}

  ;(window as any).dataLayer = (window as any).dataLayer || []
  ;(window as any).dataLayer.push({
    eventName: 'userLoginStatus',
    userLoginStatus: {
      isLoggedIn: !!user?.email
    }
  })

  return {
    userLocale: {
      currencyCode,
      countryCode,
      currencySymbol,
      countryName
    },
    lineCount,
    homeStore,
    codeMessage,
    identityLoggedUser: user,
    callCenterNumber
  }
}

export const getMiniCartInfo = async (): Promise<IMiniCartInfo> => {
  const { data } = await ATGService({
    url: '/ngp/rest/actor/DropletActor/miniCartDisplay',
    method: 'GET',
    needSession: true
  })

  const { lineCount = 0, commerceTotals, commerceItems = [] } = data ?? {}

  return {
    commerceItemDetails: {
      lineCount,
      commerceTotals,
      commerceItems
    }
  }
}
