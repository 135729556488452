export const COUNTRY_CODE = {
  us: 'US',
  ca: 'CA'
}

export const COUNTRY_NAME = {
  us: 'United States',
  ca: 'Canada'
}

export const US_states = [
  {
    name: 'Alabama',
    key: 'AL'
  },
  {
    name: 'Alaska',
    key: 'AK'
  },
  {
    name: 'American Samoa',
    key: 'AS'
  },
  {
    name: 'Arizona',
    key: 'AZ'
  },
  {
    name: 'Arkansas',
    key: 'AR'
  },
  {
    name: 'Armed Forces Americas',
    key: 'AA'
  },
  {
    name: 'Armed Forces Europe',
    key: 'AE'
  },
  {
    name: 'Armed Forces Pacific',
    key: 'AP'
  },
  {
    name: 'California',
    key: 'CA'
  },
  {
    name: 'Colorado',
    key: 'CO'
  },
  {
    name: 'Connecticut',
    key: 'CT'
  },
  {
    name: 'Delaware',
    key: 'DE'
  },
  {
    name: 'District of Columbia',
    key: 'DC'
  },
  {
    name: 'Federated States of Micronesia',
    key: 'FM'
  },
  {
    name: 'Florida',
    key: 'FL'
  },
  {
    name: 'Georgia',
    key: 'GA'
  },
  {
    name: 'Guam',
    key: 'GU'
  },
  {
    name: 'Hawaii',
    key: 'HI'
  },
  {
    name: 'Idaho',
    key: 'ID'
  },
  {
    name: 'Illinois',
    key: 'IL'
  },
  {
    name: 'Indiana',
    key: 'IN'
  },
  {
    name: 'Iowa',
    key: 'IA'
  },
  {
    name: 'Kansas',
    key: 'KS'
  },
  {
    name: 'Kentucky',
    key: 'KY'
  },
  {
    name: 'Louisiana',
    key: 'LA'
  },
  {
    name: 'Maine',
    key: 'ME'
  },
  {
    name: 'Marshall Islands',
    key: 'MH'
  },
  {
    name: 'Maryland',
    key: 'MD'
  },
  {
    name: 'Massachusetts',
    key: 'MA'
  },
  {
    name: 'Michigan',
    key: 'MI'
  },
  {
    name: 'Minnesota',
    key: 'MN'
  },
  {
    name: 'Mississippi',
    key: 'MS'
  },
  {
    name: 'Missouri',
    key: 'MO'
  },
  {
    name: 'Montana',
    key: 'MT'
  },
  {
    name: 'Nebraska',
    key: 'NE'
  },
  {
    name: 'Nevada',
    key: 'NV'
  },
  {
    name: 'New Hampshire',
    key: 'NH'
  },
  {
    name: 'New Jersey',
    key: 'NJ'
  },
  {
    name: 'New Mexico',
    key: 'NM'
  },
  {
    name: 'New York',
    key: 'NY'
  },
  {
    name: 'North Carolina',
    key: 'NC'
  },
  {
    name: 'North Dakota',
    key: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    key: 'MP'
  },
  {
    name: 'Ohio',
    key: 'OH'
  },
  {
    name: 'Oklahoma',
    key: 'OK'
  },
  {
    name: 'Oregon',
    key: 'OR'
  },
  {
    name: 'Palau',
    key: 'PW'
  },
  {
    name: 'Pennsylvania',
    key: 'PA'
  },
  {
    name: 'Puerto Rico',
    key: 'PR'
  },
  {
    name: 'Rhode Island',
    key: 'RI'
  },
  {
    name: 'South Carolina',
    key: 'SC'
  },
  {
    name: 'South Dakota',
    key: 'SD'
  },
  {
    name: 'Tennessee',
    key: 'TN'
  },
  {
    name: 'Texas',
    key: 'TX'
  },
  {
    name: 'Utah',
    key: 'UT'
  },
  {
    name: 'Vermont',
    key: 'VT'
  },
  {
    name: 'Virgin Islands',
    key: 'VI'
  },
  {
    name: 'Virginia',
    key: 'VA'
  },
  {
    name: 'Washington',
    key: 'WA'
  },
  {
    name: 'West Virginia',
    key: 'WV'
  },
  {
    name: 'Wisconsin',
    key: 'WI'
  },
  {
    name: 'Wyoming',
    key: 'WY'
  }
]

export const availableCountries = [
  {
    countryCode: 'US',
    countryName: 'United States',
    currencyCode: 'USD'
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    currencyCode: 'CAD'
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    currencyCode: 'USD'
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    currencyCode: 'ARS'
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    currencyCode: 'USD'
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    currencyCode: 'AUD'
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    currencyCode: 'BHD'
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    currencyCode: 'BDT'
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    currencyCode: 'BBD'
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    currencyCode: 'BZD'
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    currencyCode: 'USD'
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    currencyCode: 'BOB'
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    currencyCode: 'USD'
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei Darussalam',
    currencyCode: 'USD'
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    currencyCode: 'BGN'
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    currencyCode: 'KHR'
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    currencyCode: 'KYD'
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    currencyCode: 'CLP'
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    currencyCode: 'CNY'
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    currencyCode: 'COP'
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    currencyCode: 'CRC'
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    currencyCode: 'CZK'
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    currencyCode: 'DKK'
  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    currencyCode: 'USD'
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    currencyCode: 'DOP'
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    currencyCode: 'USD'
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    currencyCode: 'EGP'
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    currencyCode: 'USD'
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    currencyCode: 'GBP'
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    currencyCode: 'USD'
  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    currencyCode: 'GTQ'
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey',
    currencyCode: 'GBP'
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    currencyCode: 'HNL'
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    currencyCode: 'HKD'
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    currencyCode: 'HUF'
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    currencyCode: 'INR'
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    currencyCode: 'IDR'
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    currencyCode: 'ILS'
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    currencyCode: 'JMD'
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    currencyCode: 'JPY'
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    currencyCode: 'GBP'
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    currencyCode: 'JOD'
  },
  {
    countryCode: 'KR',
    countryName: 'Korea, Republic of',
    currencyCode: 'KRW'
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    currencyCode: 'KWD'
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    currencyCode: 'LVL'
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    currencyCode: 'LTL'
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    currencyCode: 'HKD'
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    currencyCode: 'MVR'
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    currencyCode: 'MXN'
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    currencyCode: 'USD'
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    currencyCode: 'NZD'
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    currencyCode: 'NIO'
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    currencyCode: 'NOK'
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    currencyCode: 'OMR'
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    currencyCode: 'PKR'
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    currencyCode: 'PAB'
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    currencyCode: 'PYG'
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    currencyCode: 'PEN'
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    currencyCode: 'PHP'
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    currencyCode: 'PLN'
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    currencyCode: 'QAR'
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    currencyCode: 'RON'
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    currencyCode: 'RUB'
  },
  {
    countryCode: 'RE',
    countryName: 'RÃ©union',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    currencyCode: 'USD'
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    currencyCode: 'USD'
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    currencyCode: 'SAR'
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    currencyCode: 'SGD'
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    currencyCode: 'ZAR'
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    currencyCode: 'EUR'
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    currencyCode: 'LKR'
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    currencyCode: 'SEK'
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    currencyCode: 'CHF'
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    currencyCode: 'TWD'
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    currencyCode: 'THB'
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    currencyCode: 'USD'
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    currencyCode: 'TRY'
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    currencyCode: 'AED'
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    currencyCode: 'GBP'
  }
]
