import { isObject } from 'lodash'

import { refreshCartInfo } from '../helper/business/cart'
import { refreshMiniCart } from '../helper/business/header'
import { setCookie } from './browser'

export const setGCIDigitalData = (key: string, value: any) => {
  window.GCI = window.GCI || {}
  window.GCI.digitalData = window.GCI.digitalData || {}

  window.GCI.digitalData[key] = value
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  if (isObject(value) && !value.__proto__) {
    window.GCI.digitalData[key].__proto__ = {}
  }
}

export const initGCIData = queryClient => {
  window.GCI = window.GCI || {}
  window.GCI.digitalData = window.GCI.digitalData || {}
  window.GCI.digitalData.omnitureData =
    window.GCI.digitalData.omnitureData || {}
  window.GCI.digitalData.siteVars = window.GCI.digitalData.siteVars || {}
  window.GCI.digitalData.pageData = window.GCI.digitalData.pageData || {}
  window.GCI.reactUtils = {
    refreshMiniCart: () => refreshMiniCart(queryClient),
    refreshCartInfo: () => refreshCartInfo(queryClient),
    setCookie
  }
}
