import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { get, reduce } from 'lodash'

import { getHeaderUserInfo, getMiniCartInfo } from '../../api/header'
import { updateUserLocale } from '../../api/location'
import { QUERY_KEY_MINI_CART } from '../../constants/query/cart'
import { QUERY_KEY_HEADER_USER_INFO } from '../../constants/query/header'
import type { IMiniCart } from './../../api/header'

export function useHeaderUserInfo(): IMiniCart {
  const {
    data: headerInfo = {},
    refetch,
    isLoading
  } = useQuery({
    queryKey: [QUERY_KEY_HEADER_USER_INFO],
    queryFn: getHeaderUserInfo
  })

  const queryClient = useQueryClient()
  const updateLineCount = order => {
    const commerceItems = get(order, 'commerceItems')
    const commerceItemCount = reduce(
      commerceItems,
      (sum, item) => {
        return sum + item?.quantity
      },
      0
    )

    queryClient.setQueryData([QUERY_KEY_HEADER_USER_INFO], {
      ...headerInfo,
      lineCount: commerceItemCount
    })
  }

  return {
    ...headerInfo,
    isLoading,
    updateLineCount,
    async updateUserLocale(
      countryCode: string,
      currencyCode: string
    ): Promise<void> {
      await updateUserLocale(countryCode, currencyCode)
      await refetch()
    }
  }
}

export function useMiniCart() {
  const {
    data: miniCart = {},
    refetch,
    isPending
  } = useQuery({
    queryKey: [QUERY_KEY_MINI_CART],
    queryFn: getMiniCartInfo,
    placeholderData: keepPreviousData,
    gcTime: 0
  })

  return {
    ...miniCart,
    isPending,
    refetch
  }
}
