import type { ErrorBoundaryProps } from 'react-error-boundary'
import { withErrorBoundary as originWithErrorBoundary } from 'react-error-boundary'

const initialErrorProps = {
  fallbackRender: () => <div />
}

export const withErrorBoundary = <P extends object>(
  Component: React.ComponentType<P>,
  errorBoundaryProps: ErrorBoundaryProps = initialErrorProps
): React.ComponentType<P> => {
  return originWithErrorBoundary(
    Component,
    errorBoundaryProps
  ) as unknown as React.ComponentType<P>
}
