import { isString, startsWith } from 'lodash'
import type { ImageProps } from 'next/image'
import NextImage from 'next/image'

function Image(props: ImageProps): React.ReactElement {
  const { src, ...restProps } = props

  const isStorybook =
    typeof process !== 'undefined' && process.env?.STORYBOOK === 'true'

  if (isString(src) && !startsWith(src, '//') && src !== '') {
    return <NextImage {...props} unoptimized={isStorybook} />
  }

  const finalSrc = !isString(src)
    ? 'https://media.guitarcenter.com/is/image/MMGS7/default-image-00-100x100.jpg'
    : `https:${src}`

  return <NextImage src={finalSrc} {...restProps} unoptimized={isStorybook} />
}

export default Image
