import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { changeDrawerArray, save } from '@store/global'
import type { RootState } from '@store/index'
import { useStoreInstance } from '@store/index'
import { some } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

export const useChangeDrawerArr = () => {
  const store = useStoreInstance()
  const { trackEvent } = useTracking()
  const [delayedDrawerArray, setDelayedDrawerArray] = useState<string[]>([])

  const drawerArray = useSelector(
    (state: RootState) => state.global.drawerArray
  )

  useEffect(() => {
    if (drawerArray.length < delayedDrawerArray.length) {
      // Drawer is being closed, delay 500ms to update the state for the animation
      setTimeout(() => {
        setDelayedDrawerArray(drawerArray)
      }, 500)
    } else {
      // Drawer is being opened
      setDelayedDrawerArray(drawerArray)
    }
  }, [drawerArray])

  const changeDrawer = (drawerLayoutName: string) => {
    store.dispatch(changeDrawerArray(drawerLayoutName))

    trackEvent({
      eventName: `changeDrawerDisplayStatus`,
      changeDrawerDisplayStatus: {
        drawerLayoutName
      }
    })
  }

  const judgeIsDisplayDrawer = (name: string) => {
    return isArrayAndNotEmpty(delayedDrawerArray)
      ? some(delayedDrawerArray, item => item === name)
      : false
  }

  const emptyDrawer = () => {
    store.dispatch(
      save({
        drawerArray: []
      })
    )
  }

  return { changeDrawer, judgeIsDisplayDrawer, emptyDrawer }
}
