/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  assign,
  cloneDeep,
  findIndex,
  forEach,
  get,
  isEmpty,
  isFunction,
  map,
  omit,
  pick
} from 'lodash'

import { GC_CART_PAGE_INFO_QUERY_KEY } from '../../constants/query/cart'
import { isArrayAndNotEmpty } from '../../utils/lodash'

const updateGiftItems = (targetGiftItems, sourceGiftItems) => {
  forEach(targetGiftItems, tItem => {
    const sourceIndex = findIndex(sourceGiftItems, ['id', tItem.id])
    if (sourceIndex !== -1) {
      tItem.totalQuantity = sourceGiftItems[sourceIndex].totalQuantity
    }
  })
  return targetGiftItems
}

export const updateArray = ({ targetArray, sourceArray, matchKey = 'id' }) => {
  if (targetArray.length < sourceArray.length) {
    return sourceArray
  }

  // update / add warranty / remove /
  return map(targetArray, targetItem => {
    const sourceIndex = findIndex(
      sourceArray,
      (sourceItem: any) => sourceItem[matchKey] === targetItem[matchKey]
    )
    if (sourceIndex !== -1) {
      return assign({}, targetItem, {
        ...sourceArray[sourceIndex],
        // update the latest data for warranty about cache
        warranty: !isEmpty(sourceArray[sourceIndex]?.warranty)
          ? { ...targetItem.warranty, ...sourceArray[sourceIndex]?.warranty }
          : {},
        // update the latest data for promo
        itemPromo: sourceArray[sourceIndex]?.itemPromo ?? null,
        orderPromo: sourceArray[sourceIndex]?.orderPromo ?? null,
        giftItems: isArrayAndNotEmpty(sourceArray[sourceIndex]?.giftItems)
          ? updateGiftItems(
              targetItem.giftItems,
              sourceArray[sourceIndex].giftItems
            )
          : null
      })
    }
    return 'delete'
  }).filter(item => item !== 'delete')
}

const formatOrderLeft = ({ leftPrevData, leftResponseData }) => {
  const prevSaveForLaterItems =
    get(leftPrevData, 'saveForLater.saveForLaterItems') || []

  const prevCommerceItems = get(leftPrevData, 'commerceItems') || []
  const newCommerceItems = get(leftResponseData, 'commerceItems') || []
  const newSaveForLater = get(leftResponseData, 'saveForLater') || {}

  const commerceItemsRes = updateArray({
    targetArray: prevCommerceItems,
    sourceArray: newCommerceItems
  })

  return {
    commerceItems: commerceItemsRes,
    saveForLater: {
      ...leftPrevData.saveForLater,
      // Update other data not related to save for later, do not return save for later
      saveForLaterItems: isEmpty(newSaveForLater)
        ? prevSaveForLaterItems
        : newSaveForLater?.saveForLaterItems,
      // reset pageCount when user not click loadMore btn
      currentPage:
        leftResponseData?.saveForLater?.currentPage ??
        leftPrevData?.saveForLater?.currentPage,
      totalCount:
        leftResponseData?.saveForLater?.totalCount ??
        leftPrevData?.saveForLater?.totalCount,
      pageCount:
        leftResponseData?.saveForLater?.pageCount ??
        leftPrevData?.saveForLater?.pageCount
    }
  }
}

const formatOrderRight = (rightPrev, rightResponse) => {
  const rightPrevDataOmitCoupon = omit(rightPrev, [
    'coupons',
    'orderLevelPromotions'
  ])
  return {
    ...rightPrevDataOmitCoupon,
    ...rightResponse,
    alertMessages: rightResponse?.alertMessages ?? []
  }
}

export const formatOrderResponse = ({
  prevOrderInfo,
  responseOrder
}: {
  prevOrderInfo: any
  responseOrder: any
}) => {
  const leftPrevData = pick(prevOrderInfo, [
    'saveForLater',
    'commerceItems',
    'saveForLater.currentPage',
    'saveForLater.totalCount',
    'saveForLater.pageCount'
  ])
  const leftResponseData = pick(responseOrder, [
    'saveForLater.saveForLaterItems',
    'commerceItems',
    'saveForLater.currentPage',
    'saveForLater.totalCount',
    'saveForLater.pageCount'
  ])

  const res = formatOrderLeft({
    leftPrevData,
    leftResponseData
  })

  const rightPrevData = omit(prevOrderInfo, ['saveForLater', 'commerceItems'])
  const rightResponseData = omit(responseOrder, [
    'saveForLater',
    'commerceItems'
  ])

  return {
    ...cloneDeep(res),
    ...cloneDeep(formatOrderRight(rightPrevData, rightResponseData))
  }
}

export function refreshCartInfo(queryClient = (window as any).queryClient) {
  if (isFunction(queryClient?.invalidateQueries)) {
    queryClient.invalidateQueries([GC_CART_PAGE_INFO_QUERY_KEY])
  }
}
