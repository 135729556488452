export const FORM_VALIDATE_SUCCESS = 'showSuccess'
export const FORM_VALIDATE_ERROR = 'showError'
export const OPEN_DELIVERY_FROM = 'openDeliveryForm'
export const CLOSE_DELIVERY_FROM = 'closeDeliveryForm'

export const IN_STOCK = '1000'
export const PRE_ORDER = '1002'
export const RESERVE_NOW = '1003'

// item type
export const GC_PRODUCT_COMMERCE_TYPE_USED = 'Used'
export const GC_PRODUCT_COMMERCE_TYPE_NEW = 'New'
export const GC_PRODUCT_COMMERCE_TYPE_PLATINUM = 'Platinum'
export const GC_PRODUCT_COMMERCE_TYPE_VINTAGE = 'Vintage'
export const GC_PRODUCT_COMMERCE_TYPE_CLEARANCE = 'Clearance'

export const GC_PRODUCT_COMMERCE_BRAND_NAME_AVID = 'Avid'
export const GC_PRODUCT_COMMERCE_BRAND_NAME_TOONTRACK = 'Toontrack'

export const GC_CREATE_ACCOUNT_MESSAGE =
  'Thanks! You successfully created your account.'

// drawer layout name
export const GC_DRAWER_HEADER_LIVE_HELP = 'liveHelp'
export const GC_DRAWER_HEADER_STORE_LIST = 'store-header'
export const GC_DRAWER_HEADER_SIGN_IN = 'signIn'
export const GC_DRAWER_HEADER_MEGA_MENU = 'megaMenu'
export const GC_DRAWER_HEADER_DEPART_MENU = 'departmentMenu'
export const GC_DRAWER_HEADER_DEPART_DETAIL_MENU = 'departDetail'
export const GC_DRAWER_HEADER_DEPART_THIRD_MENU = 'departThird'
export const GC_DRAWER_HEADER_SUB_MENU = 'subMenu'
export const GC_DRAWER_HEADER_MINI_CART = 'miniCartModal'

export const PDP_DOWNLOAD_GUIDE_DRAWER = 'downloadGuide'
export const PDP_STYLE_SELECTOR_MODAL_DRAWER = 'styleSelectorModal'
export const PDP_CONDITION_DRAWER = 'condition'
export const PDP_FINANCING_MODAL_DRAWER = 'financingModal'
export const PDP_LEASE_TO_OWN_MODAL_DRAWER = 'leaseToOwnModal'
export const PDP_LESSON_MODAL_DRAWER = 'lesson'
export const PDP_PROCOVERAGE_DRAWER = 'proCoverage'
export const PDP_SERIALIZED_COMPARE_MODAL_DRAWER = 'serializedCompareModal'

export const CHECKOUT_DRAWER_ROUNDUP_MODAL = 'roundUpModal'
export const HEADER_MENU_RECENTLY_VIEWED = 'header-menu-recently-viewed'

export const GLOBAL_HEADER_ICONS_AND_TEXT_JSON = {
  action: 'replace',
  source: {
    GCLogo: {
      imgSrc: '/assets/images/new-header/mobile-header-logo.svg',
      altText: 'Guitar Center logo'
    },
    proGearAdvisers: {
      imgSrc: '/assets/images/new-header/gear-advisor.png',
      altText: 'gear advisor icon',
      headerLine: 'Pro Gear Advisers',
      phoneImgSrc: '/assets/images/new-header/icon-phone.svg',
      phoneAltText: 'call center number',
      phoneNumber: '866-498-7882'
    },
    search: {
      imgSrc: '/assets/icons/header/Search.svg',
      altText: 'header search icon',
      placeHolder: 'Find your sound'
    },
    store: {
      emptyImgSrc: '/assets/images/new-header/icon-store-unselect.svg',
      emptyAltText: 'empty store icon',
      EmptyLabel: 'Select Store',
      filledImgSrc: '',
      filledAltText: 'filled store icon'
    },
    account: {
      loggedOutImgSrc: '/assets/images/new-header/icon-account-loggedOut.svg',
      loggedOutAltText: 'logged out account icon',
      loggedOutLabel: 'Sign In',
      loggedInImgSrc: '/assets/images/new-header/icon-account-logged.svg',
      loggedInAltText: 'logged account icon'
    },
    miniCart: {
      emptyImgSrc: '/assets/images/new-header/icon-cart-empty.svg',
      emptyAltText: 'empty mini cart icon',
      filledImgSrc: '/assets/images/new-header/icon-cart-filled.svg',
      filledAltText: 'filled mini cart icon'
    }
  },
  actionDest: 'pageData',
  selector: 'GlobalHeaderIconsAndText'
}
